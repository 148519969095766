import React, { useEffect, useState } from "react";
import { Button, Col, Empty, Rate, Row, Select } from "antd";
import { useParams } from "react-router";
import PhoneInput from "react-phone-input-2";
import debounce from "lodash/debounce";
import last from "lodash/last";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

import InputWithLabel from "../../shared/InputWithLabel";
import SelectWithLabel from "../../shared/SelectWithLabel";
import { Controller, useForm } from "react-hook-form";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import Label from "../../shared/Form/Label";
import { ISO31661ALPHA2, regions } from "../../../api/constants";
import UploadDragger from "../../shared/UploadDragger";
import {
  addHotelRequest,
  getHotelRequest,
  getHotelsFacilitiesRequest,
  clearHotelDetailsAction,
  updateHotelRequest
} from "../../../redux/admin/hotels/hotelsActions";
import { getPlaceDetailsAdminRequest, getPlacesAdminRequest } from "../../../redux/admin/places/placesActions";

const HotelSchema = yup.object().shape({
  name: yup.string().trim().max(255).required("Hotel name can not be empty"),
  latitude: yup.string().trim().required("Latitude can not be empty"),
  longitude: yup.string().trim().required("Longitude can not be empty"),
  email: yup.string().trim().email("Please provide correct email").nullable(),
  faxNumber: yup.string().trim().max(255),
  phoneNumber: yup.string().trim().test({
    name: "phoneLength",
    test: value => (!!value && /[0-9]/.test(value) && value.length > 10 && value.length < 20) || !value,
  }),
  rating: yup.number().min(1).required("Star rating can not be empty"),
  description: yup.string().trim()
    .test({
      name: "descriptionLength",
      message: "Hotel description can not be empty",
      test: value => {
        let text = "";
        if (value) {
          text = value.replace(/(<([^>]+)>)/ig, "");
        }

        return !!text.length;
      }
    }),
  address: yup.string().required("Address can not be empty"),
  city: yup.string().trim().required("City can not be empty"),
  country: yup.string().trim().required("Country / Region can not be empty"),
  state: yup.mixed().when("country", {
    is: value => value && ["US", "CA"].includes(value),
    then: yup.mixed().required("State can not be empty"),
    otherwise: yup.mixed().notRequired()
  }),
  postcode: yup.string().trim().required("Zip/Post Code can not be empty"),
  facilities: yup.array().notRequired(),
  images: yup.mixed()
});

export const Hotel = ({
  isProcessing,
  placeDetails,
  places,
  facilities,
  hotel,
  getPlaces,
  getFacilities,
  getPlaceDetails,
  addHotel,
  getHotel,
  updateHotel,
  clearHotelDetails
}) => {
  const { id } = useParams();
  const history = useHistory();

  const [locations, setLocations] = useState(places);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(false);

  const { handleSubmit, control, errors, reset, watch, setValue, getValues } = useForm({
    validationSchema: HotelSchema
  });

  useEffect(() => {
    getFacilities();

    if (id !== "new") {
      getHotel({ id });
    }

    return clearHotelDetails;
  }, []);

  useEffect(() => {
    if (hotel.id) {
      setSelectedCountry(hotel?.HotelAddress?.country);
    }
  }, [hotel]);

  useEffect(() => {
    reset({
      name: hotel.name,
      latitude: hotel.location.coordinates[1],
      longitude: hotel.location.coordinates[0],
      email: hotel?.emails?.[0],
      phoneNumber: hotel?.phoneNumbers?.[0],
      faxNumber: hotel?.faxNumbers?.[0],
      rating: hotel.rating,
      description: hotel.description,
      address: hotel?.HotelAddress?.addressLine,
      city: hotel?.HotelAddress?.cityName,
      country: hotel?.HotelAddress?.country,
      state: hotel?.HotelAddress?.state,
      postcode: hotel?.HotelAddress?.postalCode,
      facilities: hotel.facilities,
      images: {
        fileList: hotel.HotelImages
          .filter(image => !!image.imageUrl)
          .map(image => {
            return {
              name: last(image.imageUrl.split("/")),
              status: "done",
              url: image.imageUrl,
              uid: image.id
            }
          })
      }
    })
  }, [hotel]);

  useEffect(() => {
    if (places.length) {
      setLocations(places);
    }
  }, [places]);

  useEffect(() => {
    if (getValues("placeId")) {
      getPlaceDetails({ id: getValues("placeId") })
    }
  }, [watch("placeId")]);

  useEffect(() => {
    setValue("latitude", placeDetails.latitude);
    setValue("longitude", placeDetails.longitude);
  }, [placeDetails]);

  function searchCity(query) {
    if (query.length > 2) {
      getPlaces({ input: query });
    }
  }

  const submitHandler = (data) => {
    const isImagesValid = data.images.fileList.reduce((isValid, file) => {
      return !file.type || ["image/jpeg", "image/jpg", "image/gif", "image/png"].includes(file.type);
    }, true);

    if (!isImagesValid) {
      return setFileTypeError(true);
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("latitude", data.latitude);
    formData.append("longitude", data.longitude);

    if (data.email) {
      formData.append("email", data.email);
    }

    if (data.phoneNumber) {
      formData.append(
        "phoneNumber",
        data.phoneNumber.includes("+") ? data.phoneNumber : `+${data.phoneNumber}`
      );
    }

    if (data.faxNumber) {
      formData.append("faxNumber", data.faxNumber);
    }

    formData.append("rating", data.rating);
    formData.append("description", data.description);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("country", data.country);
    formData.append("postcode", data.postcode);

    if (data.state) {
      formData.append("state", data.state);
    }

    if (data.facilities.length) {
      data.facilities.forEach((facilityId) => {
        const facility = facilities.find(defaultFacility => {
          return [defaultFacility.code, defaultFacility.name].includes(facilityId);
        });

        formData.append("facilities[]", facility.code);
      })

    }

    if (data.images.fileList.length) {
      data.images.fileList.forEach(image => {
        formData.append("images[]", image.originFileObj || image.uid);
      });
    }


    if (id === "new") {
      addHotel(formData, (hotelId) => history.push(`/admin/hotels/${hotelId}`));
    } else {
      formData.set("id", id);

      updateHotel(formData);
    }
  };

  return (
    <div className="admin-hotel">
      <Row>
        <Col span={24}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Row>
              <Col span={24} className="form-section">
                <Row>
                  <div className="form-section__title">{id === "new" ? "New hotel" : "Edit hotel"}</div>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <InputWithLabel
                      name="name"
                      control={control}
                      id="name"
                      label="Hotel Name"
                      error={errors.name}
                      placeholder="Enter hotel name"
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <label htmlFor="placeId" className="form-label">Hotel Location</label>
                    <Controller
                      name="placeId"
                      as={<Select
                        showSearch
                        showArrow={false}
                        className={`form-section__input${errors.placeId ? " form-section__field--error" : ""}`}
                        placeholder="Enter location"
                        dropdownClassName="admin-hotel__dropdown-location"
                        filterOption={() => {
                          return true;
                        }}
                        onSearch={debounce(value => searchCity(value), 500)}
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Enter a location" />}
                        optionLabelProp="label"
                      >
                        {
                          locations.map(item => {
                            const labels = item.label.split(", ");

                            return (
                              <Select.Option key={item.placeId} value={item.placeId} label={item.label}>
                                <div className="listing-search__place">
                                  <img src="/images/location.svg" alt="location" className="listing-search__place-icon"/>
                                  <div>
                                    <div className="listing-search__place-title">{labels.shift()}</div>
                                    <div className="listing-search__place-subtitle">{labels.join(", ")}</div>
                                  </div>
                                </div>
                              </Select.Option>
                            )
                          })
                        }
                      </Select>}
                      control={control}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="form-section__field" key={`hotel-latitude-${placeDetails.latitude}`}>
                    <InputWithLabel
                      name="latitude"
                      control={control}
                      id="latitude"
                      label="Latitude"
                      error={errors.latitude}
                      placeholder="Hotel latitude"
                      disabled
                    />
                  </Col>
                  <Col span={12} className="form-section__field" key={`hotel-longitude-${placeDetails.longitude}`}>
                    <InputWithLabel
                      name="longitude"
                      control={control}
                      id="longitude"
                      label="Longitude"
                      error={errors.longitude}
                      placeholder="Hotel longitude"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <InputWithLabel
                      name="email"
                      control={control}
                      id="email"
                      label="Email address"
                      error={errors.email}
                      placeholder="Enter email address"
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="form-section__field">
                    <Label>Phone Number</Label>
                    <Controller
                      name="phoneNumber"
                      as={<PhoneInput
                        id="phoneNumber"
                        className={`profile__input ${errors.phoneNumber ? "profile__input--error" : ""}`}
                        country="gb"
                        autoFormat
                        enableSearch
                        disableSearchIcon
                        countryCodeEditable={true}
                      />}
                      control={control}
                    />
                    {errors.phoneNumber &&
                      <span className="profile__error">Invalid phone number</span>
                    }
                  </Col>
                  <Col span={12} className="form-section__field">
                    <InputWithLabel
                      name="faxNumber"
                      control={control}
                      id="faxNumber"
                      label="Fax number"
                      error={errors.faxNumber}
                      placeholder="Enter fax number"
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <Label>Star rating</Label>
                    <Controller
                      name="rating"
                      as={<Rate/>}
                      control={control}
                    />
                    {errors.rating && <span className="profile__error">Rating can not be empty</span>}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <label htmlFor="description" className="form-label">Hotel description</label>
                    <Controller
                      name="description"
                      as={<RichTextEditor/>}
                      control={control}
                      onChange={([value]) => value}
                    />
                    {errors.description && <span className="profile__error">{errors.description.message}</span>}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <SelectWithLabel
                      name="country"
                      control={control}
                      id="country"
                      label="Country / Region"
                      showSearch={true}
                      onChange={value => {
                        setSelectedCountry(value[0]);

                        if (value[0] !== watch("country")) {
                          setValue("state", null);
                        }

                        return value[0];
                      }}
                      error={errors.country}
                      options={ISO31661ALPHA2
                        .map(country => {
                          return {
                            value: country.code,
                            label: country.name
                          };
                        })
                      }
                      placeholder="Select"
                    />
                  </Col>
                </Row>
                {["US", "CA"].includes(selectedCountry) &&
                  <Row>
                    <Col span={24} className="form-section__field">
                      <SelectWithLabel
                        name="state"
                        control={control}
                        id="state"
                        label="State"
                        showSearch={true}
                        onChange={value => {
                          return value[0];
                        }}
                        error={errors.state}
                        options={regions[selectedCountry].map(state => {
                          return {
                            value: state.code,
                            label: state.name
                          };
                        })}
                        placeholder="Select State"
                      />
                    </Col>
                  </Row>
                }
                <Row>
                  <Col span={24} className="form-section__field">
                    <InputWithLabel
                      name="city"
                      control={control}
                      id="city"
                      label="City"
                      error={errors.city}
                      placeholder="Enter city name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <InputWithLabel
                      name="address"
                      control={control}
                      id="address"
                      label="Address"
                      error={errors.address}
                      placeholder="Enter address"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <InputWithLabel
                      name="postcode"
                      control={control}
                      id="postcode"
                      label="Postcode"
                      error={errors.postcode}
                      placeholder="Enter postcode"
                      onChange={([event]) => {
                        return event.target.value ? event.target.value.toUpperCase() : null;
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="form-section__field">
                    <SelectWithLabel
                      mode="multiple"
                      name="facilities"
                      showSearch={true}
                      control={control}
                      id="facilities"
                      onChange={value => {
                        return value[0];
                      }}
                      label="Facilities"
                      error={errors.facilities}
                      options={facilities.map(facility => ({ value: facility.code, label: facility.name }))}
                      placeholder="Select"
                    />
                  </Col>
                </Row>
                <Row className={fileTypeError ? " file-upload__files--error" : ""}>
                  <Col span={24} className="form-section__field">
                    <Controller
                      name="images"
                      as={<UploadDragger
                        title="Hotel images"
                        accept=".webp,.jpeg,.jpg,.gif,.png"
                        maxCount={15}
                        multiple
                        showUploadList={true}
                        beforeUpload={() => false}
                        fileList={watch("images") ? watch("images").fileList : []}
                      />
                      }
                      control={control}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="admin-article__submit">
                    <Button
                      htmlType="submit"
                      disabled={isProcessing}
                      type="primary"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
}

Hotel.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  placeDetails: PropTypes.object.isRequired,
  places: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  hotel: PropTypes.object.isRequired,
  getPlaces: PropTypes.func.isRequired,
  getFacilities: PropTypes.func.isRequired,
  getPlaceDetails: PropTypes.func.isRequired,
  addHotel: PropTypes.func.isRequired,
  getHotel: PropTypes.func.isRequired,
  updateHotel: PropTypes.func.isRequired,
  clearHotelDetails: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.hotels.processing,
    placeDetails: state.admin.places.singlePlace,
    places: state.admin.places.list,
    facilities: state.admin.hotels.facilities,
    hotel: state.admin.hotels.singleHotel
  }),
  {
    getPlaces: getPlacesAdminRequest,
    getFacilities: getHotelsFacilitiesRequest,
    getPlaceDetails: getPlaceDetailsAdminRequest,
    addHotel: addHotelRequest,
    getHotel: getHotelRequest,
    updateHotel: updateHotelRequest,
    clearHotelDetails: clearHotelDetailsAction
  }
)(Hotel)

